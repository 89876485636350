import React, { useState,useEffect } from "react";
import   {useNavigate}  from "react-router-dom";
import https from '../https';
import { useForm } from "react-hook-form";
import { PiEyeSlashLight } from "react-icons/pi";
import './Admin.css';
import CheckAdmin from "../CheckAdmin";
export default function Admin() {

    useEffect(() => {
        CheckAdmin()===true ? navigate('/') : console.log();


    }, []);


    const [type,settype]=useState(false);
    const navigate = useNavigate();
    
    const [data_error, setdata_error] = useState(['']);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const handel_password=()=>{
        settype(!type)
    }
    const handel_login= async(data)=>{
         https.post('Admin/Login',data).then(res=>{
            localStorage.setItem('token',res.data.data.token)
            navigate('/')
            
        }).catch(errors=>{
            
            if (errors.response.data.succes===false) {
                setdata_error(errors.response.data.message.password);
            }if(errors.response.status===401){
                setdata_error('Please check your data and try again');
            }
            
        })
    }
  return (
    <div className="login_page">
    <div className="top">
        <h1>Login</h1>
    </div>
    <div className="main">
        <form onSubmit={handleSubmit(handel_login)}>
            <div className="form">
                <div className="input_form">
                        <label htmlFor="email">email</label>
                        <input type="email" name="email" placeholder="Email" {...register('email',{required:'Email is Required',pattern:{value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,message:"Invalid email address"}})}/>
                        {errors.email && <p className="error">{errors.email.message}</p>}

                    </div>                            
                    <div className="input_form password">
                        <label htmlFor="password">password</label>
                        <input  type={type===true ? 'text': "password" } autocomplete="on" name="password" placeholder="Password" {...register('password',{required:'password is Required'})} />
                        {errors.password && <p className="error">{errors.password.message}</p>}
                        
                        <PiEyeSlashLight onClick={handel_password}/>
                        
                    </div>
                {data_error ===''?'': <p className="error_email">{data_error}</p>}
            <button type="submit">login</button>
            </div>
        </form>
        
        <div className="link_create_account">

        </div>

    </div>
</div>  
)
}

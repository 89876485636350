import React, { useEffect, useState } from 'react'
import Nav_bar from '../Nav_bar/Nav_bar'
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/logo.webp';    
import CheckAdmin from '../CheckAdmin';
import { useForm } from "react-hook-form";
import https from '../https';
import { PiEyeSlashLight } from 'react-icons/pi';
import toast, { Toaster } from 'react-hot-toast';

import './Profile.css';
export default function Profile() {
    const notify = () => toast.success('The modification succeeded');
    const notify_error = () => toast.error('Please try agin later');
    const navigate = useNavigate();
    useEffect(() => {
        CheckAdmin()===false ? navigate('/Admin') : console.log();
        
    }, []);
    const [data_error, setdata_error] = useState();


    const { register, handleSubmit, formState: { errors },setValue } = useForm();
    
    const [type,settype]=useState(false);
    
    const handel_password=()=>{
        settype(!type)
    }
    const handel_login= async(data)=>{
        https.post('Admin/ChangeData',data).then(res=>{
            // console.log(res);
            notify()
            setValue('CurrentEmail','');
            setValue('CurrentPassword','');
            setValue('NewEmail','');
            setValue('NewPassword','');
        }).catch(errors=>{

            notify_error()
            if(errors.response.status===401){
                setdata_error('Please check your data and try again');
                notify_error()
            }
            
        })
   }
  return (
    <div className='Profile'>
                <Toaster />

        <Nav_bar/>
        <br />
        <br />
        <div className="top">
        <h1>Change Email And Password</h1>
    </div>
    <div className="main">
        <form onSubmit={handleSubmit(handel_login)}>
            <div className="form">
                <div className="input_form">
                        <label htmlFor="email">current email</label>
                        <input type="email" name="CurrentEmail" placeholder="Current Email" {...register('CurrentEmail',{required:'Current Email is Required',pattern:{value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,message:"Invalid email address"}})}/>
                        {errors.CurrentEmail && <p className="error">{errors.CurrentEmail.message}</p>}

                    </div>                            
                    <div className="input_form password">
                        <label htmlFor="password">Current Password</label>
                        <input type={type===true ? 'text': "password" }  autoComplete="new-password" name="CurrentPassword" placeholder="Current Password" {...register('CurrentPassword',{required:'Current Password is Required',minLength:8})} />
                        {errors.CurrentPassword && <p className="error">{errors.CurrentPassword.message}</p>}
                        {errors.CurrentPassword?.type === "minLength" && (<p className="error" role="alert">min Length 8 characters </p>)}
                        <PiEyeSlashLight onClick={handel_password}/>
                        
                    </div>
                    {data_error ===''?'': <p className="error_email">{data_error}</p>}
             
                <h3>New Data</h3>


                <div className="input_form">
                        <label htmlFor="email">new email</label>
                        <input type="email" name="NewEmail" placeholder="New Email" {...register('NewEmail',{required:'New Email is Required',pattern:{value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,message:"Invalid email address"}})}/>
                        {errors.NewEmail && <p className="error">{errors.NewEmail.message}</p>}

                    </div>                            
                    <div className="input_form password">
                        <label htmlFor="password">new password</label>
                        <input type={type===true ? 'text': "password" } min={8} autoComplete="new-password" name="NewPassword" placeholder="New Password" {...register('NewPassword',{required:'New Password is Required',minLength:8})} />
                        {errors.NewPassword && <p className="error">{errors.NewPassword.message}</p>}
                        {errors.NewPassword?.type === "minLength" && (<p className="error" role="alert">min Length 8 characters </p>)}
                        <PiEyeSlashLight onClick={handel_password}/>
                        
                    </div>
            <button type="submit">login</button>
            </div>
        </form>
        
        <div className="link_create_account">

        </div>

    </div>
    </div>
  )
}

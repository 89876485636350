import React, { useEffect, useState } from 'react'
import './Team.css';
import Nav_bar from '../Nav_bar/Nav_bar';
import https from '../https';
import toast, { Toaster } from 'react-hot-toast';
import CheckAdmin from '../CheckAdmin';
import { useNavigate } from 'react-router-dom';

export default function Team() {
    const navigate = useNavigate();

    const notify = () => toast.success('The Member has been added');
    const notify_error = () => toast.error('Please try agin later');
    const notify_Delete = () => toast.success('The Member has been Deleted');

    const [Name, setName] = useState('');
    const [Data, setData] = useState([]);
    useEffect(() => {
        CheckAdmin()===false ? navigate('/Admin') : console.log();

        https.get('Team/Get').then(
            res =>{
                setData(res.data)
                
            }
        )        

    }, []);
    
    function Delete(e){
        
        https.post('Team/Delete',{
            "id":e
        }).then(
            res=>{
                setData(Data => {
                    return Data.filter(item => item.id !== e)
                })    
             
                notify_Delete()
                
            }
        ).catch(res=>{
            notify_error()

        })

   
    }
    // console.log(Data[5]);
    
    function StoreMember(e){
        e.preventDefault();
        https.post('Team/Store',{
            "name":Name
        }).then(
            res=>{

                notify()
                let data=Data;
                data.push({id:res.data.data,name:Name})
                
                setData(data)
                setName('');
                
            }
        ).catch(res=>{
            notify_error()
            setName('');

        })
    }
    
    return (
    <div className='Team'>
                    <Toaster />

      <Nav_bar/>
        <div className="container T_content">
            <h1>Team</h1>
            <br />
            <div className='form'>
                <form onSubmit={StoreMember}>
                    <input type="text"  value={Name} placeholder='name' onChange={(e)=>setName(e.target.value)}/>
                    <button  >Submit</button>
                </form>
            </div>
            <br />
        <table>
            <tr>
              <th>Name</th>
              <th>Controller</th>
            </tr>
            {
                Data.map((item,index)=>{
                    return (
                        <tr key={index} >
                        <td>{item.name}</td>
                        <td><button onClick={()=>Delete(item.id)}>Delete</button></td>
                        </tr>
                    
                    )
                })
            }

      </table>

        </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import Nav_bar from '../Nav_bar/Nav_bar'
import { PiUsersThree } from 'react-icons/pi'
import { Link, useNavigate } from 'react-router-dom'
import './Home.css';
import { CiBookmarkCheck } from 'react-icons/ci';
import { FcLike } from 'react-icons/fc';
import https from '../https';

import { FiMessageSquare } from 'react-icons/fi';
import Slider from '../Slider/Slider';
import CheckAdmin from '../CheckAdmin';
export default function Home() {
    const navigate = useNavigate();

    const [Data, setData] = useState({});
    useEffect(() => {
        CheckAdmin()===false ? navigate('/Admin') : console.log();
        
        https.get('Home').then(
            res =>{
                setData(res.data)
                // console.log(res.data);
                
            }
        )        

    }, []);


    const items_cart=[
        {
            head:'Appointments',
            number:Data.Appointment,
            text:'See all Appointments',
            icon:<CiBookmarkCheck color='crimson'/>,
            link:'/Appointments'

        },
        {
            head:'Ratings',
            number:Data.Team,
            text:'See all Ratings',
            icon:<FcLike />,
            link:'/Survey'


        },
        {
            head:'Messages',
            number:Data.Messages,
            text:'See all Messages',
            icon:<FiMessageSquare  color='green'/>,
            link:'/ContactUS'


        },
        {
            head:'Team',
            number:Data.Survey,
            text:'See all users',
            icon:<PiUsersThree />,
            link:'/Team'


        },

    ]
    return (
    <div className='Home'>
        <Nav_bar/>
        <br />
        <div className="items_cart container">
            {
                items_cart.map((item,index)=>{
                    return(
                        <Link to={item.link} key={index}>

                            <div key={index} className="item_cart">
                                <dir className='left'>
                                    <h2>{item.head}</h2>
                                    <span>{item.number}</span>
                                    <Link to='/'>{item.text}</Link>
                                </dir>
                                <div className='right'>
                                    {item.icon}
                                </div>
                            </div>
                        </Link>

                    )
                })
            }
        </div>
        <div className="container">
            <Slider/>
        </div>
    </div>
  )
}

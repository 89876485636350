import React from 'react'
import './Nav_bar.css';
import logo from '../assets/logo.webp';
import { FaBars, FaUsers } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import { MdHomeRepairService, MdOutlineHomeRepairService } from 'react-icons/md';
import { TbMessages, TbReportSearch } from 'react-icons/tb';
import { RiSurveyLine } from 'react-icons/ri';
import { CiLogout } from 'react-icons/ci';
export default function Nav_bar() {
  const navigate = useNavigate();

    function handel_side_bar() {
        const side_bar=document.querySelector('.side_bar');
        side_bar.classList.toggle('active_bar')
    }

    function Logout(){
        localStorage.removeItem('token');
        navigate('/Admin')
    }
  return (
    <div className="Nav_bar">
        <div className='container'>
        <div className="nav_content">
            <FaBars  onClick={handel_side_bar}/>

            <div>
                <Link to='/Profile'><img src={logo} alt="" /></Link>
            </div>
        </div>
      </div>
      <div className="side_bar">
        <div className="head"><h2>Dashboard</h2></div>
            <div className="bar_close">
            <IoMdClose  className='close' onClick={handel_side_bar}/>

            </div>
            <ul>
            <li className='link_page'><Link to='/'><MdHomeRepairService /><span>Home</span></Link></li>
            <li className='link_page'><Link to='/Appointments'><RiSurveyLine/> <span>Appointments</span></Link></li>
            <li className='link_page'><Link to='/Team'><FaUsers /><span>Team</span> </Link></li>
            <li className='link_page'><Link to='/ContactUS'><TbMessages /> Messages</Link></li>
            <li className='link_page'><Link to='/Survey'><TbReportSearch/><span>Survey</span></Link></li>
            <li className='link_page'><Link to='/Services'><MdOutlineHomeRepairService/><span>Services</span></Link></li>
            <li className='logout ' onClick={Logout}><CiLogout /><span>Logout</span></li>
            

          </ul>
        </div>        
    </div>
  )
}

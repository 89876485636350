import logo from './logo.svg';
import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './Component/Home/Home';
import Appointments from './Component/Appointments/Appointments';
import Team from './Component/Team/Team';
import ContactUS from './Component/ContactUS/ContactUS';
import Survey from './Component/Survey/Survey';
import Services from './Component/Services/Services';
import Admin from './Component/Admin/Admin';
import Profile from './Component/Profile/Profile';

function App() {
  let routers=createBrowserRouter([
    {
      // path:"",
      // element:<MainLayouts/>,
      children:[
        {
          index:true,
          element:<Home/>
        },
        {
          path:"/",
          element:<Home/>
        },

        {
          path:"/Appointments",
          element:<Appointments/>
        },

        {
          path:"/Team",
          element:<Team/>
        },
        {
          path:"/ContactUS",
          element:<ContactUS/>
        },
        {
          path:"/Survey",
          element:<Survey/>
        },
        {
          path:"/Services",
          element:<Services/>
        },
        {
          path:"/Admin",
          element:<Admin/>
        },

        {
          path:"/Profile",
          element:<Profile/>
        },

        
        
        
      
      ]
      }
    ])
  
  return (
    <div className="App">
       <RouterProvider router={routers}/>

    </div>
  );
}

export default App;

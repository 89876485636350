import React, { useEffect, useState } from 'react'
import './Services.css';
import Nav_bar from '../Nav_bar/Nav_bar';
import https from '../https';
import toast, { Toaster } from 'react-hot-toast';
import CheckAdmin from '../CheckAdmin';
import {  useNavigate } from 'react-router-dom';
export default function Services() {
    const notify = () => toast.success('The Service has been added');
    const notify_error = () => toast.error('Please try agin later');
    const notify_Delete = () => toast.success('The Member has been Deleted');
    const navigate = useNavigate();

    const [Name, setName] = useState('');
    const [NameAr, setNameAr] = useState('');
    const [Price, setPrice] = useState(0);
    const [Data, setData] = useState([]);
    useEffect(() => {
        CheckAdmin()===false ? navigate('/Admin') : console.log();

        https.get('Services/Get').then(
            res =>{
                setData(res.data)
                
            }
        )        

    }, []);
    
    function Delete(e){
        
        https.post('Services/Delete',{
            "id":e
        }).then(
            res=>{
                setData(Data => {
                    return Data.filter(item => item.id !== e)
                })    
             
                notify_Delete()
                
            }
        ).catch(res=>{
            notify_error()

        })

   
    }
    // console.log(Data[5]);
    
    function StroeService(e){
        e.preventDefault();
        https.post('Services/Store',{
            "title":Name,
            "title_ar":NameAr,
            "price":Price
        }).then(
            res=>{

                notify()
                let data=Data;
                data.push({id:res.data.data,title:Name,title_ar:NameAr,price:Price})
                
                setData(data)
                setName('');
                setNameAr('');
                setPrice('');
                
            }
        ).catch(res=>{
            notify_error()
            setName('');
            setNameAr('');

            setPrice('');


        })
    }


  return (
    <div className='Services'>
                            <Toaster />

<Nav_bar/>
  <div className="container T_content">
      <h1>Services</h1>
      <br />
      <div className='form'>
          <form onSubmit={StroeService}>
              <div className="input">
              <input type="text"  required value={Name} placeholder='Title Service' onChange={(e)=>setName(e.target.value)}/>
              <input type="text" className='i_ar' required value={NameAr} placeholder='عنوان الخدمه' onChange={(e)=>setNameAr(e.target.value)}/>

              </div>
              <br />
              <input type="number"  required value={Price} placeholder='Price Service' onChange={(e)=>setPrice(e.target.value)}/>
              <button  >Submit</button>
          </form>
      </div>
      <br />
  <table>
      <tr>
        <th>#</th>
        <th>Service</th>
        <th>Service AR</th>
        <th>Price</th>
        <th>Controller</th>
      </tr>
      {
          Data.map((item,index)=>{
              return (
                  <tr key={index} >
                  <td>{index}</td>
                  <td>{item.title}</td>
                  <td>{item.title_ar}</td>
                  <td>{item.price}</td>
                  <td><button onClick={()=>Delete(item.id)}>Delete</button></td>
                  </tr>
              
              )
          })
      }

</table>

  </div>

    </div>
  )
}

import React, { useEffect, useState } from 'react'
import Nav_bar from '../Nav_bar/Nav_bar'
import './Survey.css';
import https from '../https';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import CheckAdmin from '../CheckAdmin';
export default function Survey() {
    const [Data, setData] = useState([]);
    const notify_error = () => toast.error('Please try agin later');
    const notify_Delete = () => toast.success('The Message has been Deleted');
    const navigate = useNavigate();

    useEffect(() => {
      CheckAdmin()===false ? navigate('/Admin') : console.log();

        https.get('Survey/Get').then(
            res =>{
                setData(res.data)
                
            }
        )        
  
    }, []);
    function Delete(e){
          
      https.post('Survey/Delete',{
          "id":e
      }).then(
          res=>{
              setData(Data => {
                  return Data.filter(item => item.id !== e)
              })    
           
              notify_Delete()
              
          }
      ).catch(res=>{
          notify_error()
  
      })
  
  
    }
  return (
    <div className='Survey'>
              <Toaster />

      <Nav_bar/>
      <div className="container">
      <h1>Customer opinions</h1>

<table>
  <tr>
    <th className='id'>#</th>
    <th>Name</th>
    <th>Email</th>
    <th>Stars</th>
    <th>Message</th>
    <th>Controller</th>
  </tr>
    {
      Data.map((item,index)=>{
        return(
          <tr >
            <td>{index+1}</td>
            <td>{item.name}</td>
            <td>{item.email}</td>
            <td>{item.stars}</td>
            <td>{item.message}</td>
            <td><button onClick={()=>Delete(item.id)}>Delete</button></td>

        </tr>
        )
      })
    }
  

  
</table>

      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import './Slider.css';
import https from '../https';
import toast, { Toaster } from 'react-hot-toast';
import { IoIosCloudUpload } from 'react-icons/io';
import image from '../assets/home-slider.jpg';
export default function Slider() {
    const [Data, setData] = useState([]);
    const notify = () => toast.success('The Image has been added');
    const notify_error = () => toast.error('Please try agin later');
    const notify_Delete = () => toast.success('The Image has been Deleted');
    const [file, setfile] = useState(false);

    function Store(e){
        e.preventDefault();
        https.post('Slider/Store',{
            "image":file
        }).then(
            res=>{

                notify()
                let data=Data;
                data.push(res.data.data)
                setData(data);
                setfile(false);
                
                // setName('');
                
            }
        ).catch(res=>{
            notify_error()
     
        })
    }
    useEffect(() => {
        https.get('Slider/Get').then(
            res =>{
                
                setData(res.data)
                
            }
        )        
  
    }, []);
    function Delete(e){
        console.log(e);
        
      https.post('Slider/Delete',{
          "id":e
      }).then(
          res=>{
              setData(Data => {
                  return Data.filter(item => item.id !== e)
              })    
           
              notify_Delete()
              
          }
      ).catch(res=>{
          notify_error()
  
      })
  
  
    }
  return (
    <div className='Slider'>
            <Toaster />

      <h1>Slider</h1>
      <div className="top">
        <h2>add new Image </h2>
            <div className="image">
                <img src={file ? URL.createObjectURL(file): ''} alt="" />
            </div>
        <div className="upload">
            <form onSubmit={Store}>
                <label htmlFor="upload">
                    <IoIosCloudUpload />

                </label>
                <input type="file" id="upload" onChange={e=>setfile(e.target.files[0])} />
                <button>Submit</button>
            </form>
        </div>
      </div>
      <table>
            <tr>
              <th className='id'>#</th>
              <th>Image</th>
              <th>Controller</th>
            </tr>
              {
                Data.map((item,index)=>{
                  return(
                    <tr key={index}>
                      <td width={1}>{index+1}</td>
                      <td><img src={item.image} alt="" /></td>
                      <td><button onClick={()=>Delete(item.id)}>Delete</button></td>

                  </tr>
                  )
                })
              }
            

            
      </table>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import Nav_bar from '../Nav_bar/Nav_bar'
import './Appointments.css';
import https from '../https';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import CheckAdmin from '../CheckAdmin';

export default function Appointments() {
  const [Data, setData] = useState([]);
  const notify_error = () => toast.error('Please try agin later');
  const notify_Delete = () => toast.success('The Appointment has been Deleted');
  const navigate = useNavigate();

  useEffect(() => {
    CheckAdmin()===false ? navigate('/Admin') : console.log();

      https.get('Appointment/Get').then(
          res =>{
              setData(res.data)
              
          }
      )        

  }, []);
  function Delete(e){
        
    https.post('Appointment/Delete',{
        "id":e
    }).then(
        res=>{
            setData(Data => {
                return Data.filter(item => item.id !== e)
            })    
         
            notify_Delete()
            
        }
    ).catch(res=>{
        notify_error()

    })


  }
  return (
    <div className='Appointments'>
        <Toaster />

      <Nav_bar/>
      <br />
      <br />
      <div className="container">
        <h1>Appointments</h1>

          <table>
            <tr>
              <th className='id'>#</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Date</th>
              <th>Service</th>
              <th>Message</th>
              <th>Controller</th>
            </tr>
              {
                Data.map((item,index)=>{
                  return(
                    <tr >
                      <td>{index+1}</td>
                      <td>{item.name}</td>
                      <td>{item.phone}</td>
                      <td>{item.date}</td>
                      <td>{item.service}</td>
                      <td>{item.message}</td>
                      <td><button onClick={()=>Delete(item.id)}>Delete</button></td>

                  </tr>
                  )
                })
              }
            

            
      </table>
      </div>
    </div>
  )
}
